<template>
  <div class="home">
    <div class="rco-box">
      <!-- <img src="~@/assets/img/images/solution1.png" alt="" /> -->
      <img
        src="https://platform-1309634311.cos.ap-shanghai.myqcloud.com/public/website/solution1.png"
        alt=""
      />
      <div class="container-box dv-position">区域病理中心解决方案</div>
    </div>
    <div class="banner-box">
      <!-- <img src="~@/assets/img/images/solution2.png" alt="" /> -->
      <img
        src="https://platform-1309634311.cos.ap-shanghai.myqcloud.com/public/website/solution2.png"
        alt=""
      />
      <div class="container-box p-position">
        <p class="p1 pdt">
          通过总体规划，整合区域内信息资源、以信息安全为技术支撑，根据国家“十四五”规划完善医疗协作功能实现区域医疗资源合理配置和下沉，及结合病理科发展需求，模块化建设智慧病理框架结构。
          在保障病理业务精细化发展的同时，更能支持医院病理科开展数字化、区域化、智能化病理协同业务
        </p>
        <p class="p1">
          以信息化的形式实现工作全流程闭环管理，实现远程协同于一体，真正实现“统一标准、统一平台、资源共享、互联互通”的目标，为病理学科建设提供科学、可靠、全面的数字化信息解决方案。
        </p>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import TopBg from "@/components/TopBg.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Solution",
  components: {
    TopBg,
    Footer
  },
  data() {
    return {};
  },
  created() {}
};
</script>

<style lang="scss" scoped>
.rco-box {
  margin-top: 64px;
  width: 100%;
  display: flex;
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
}
.dv-position {
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  font-size: 46px;
  color: #fff;
}
.p-position {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  .p1 {
    font-size: 16px;
    line-height: 26px;
    color: #262626;
    width: 970px;
    text-indent: 2em;
    // text-align: center;
  }
  .pdt {
    padding-top: 3%;
  }
}
.banner-box {
  display: flex;
  width: 100%;
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
}
</style>
